.avatarWrapper {
  width: "100%";
}

[id^="NotiflixConfirmWrap"]
  > div[class*="-content"]
  > div[class*="-buttons"]
  > a.nx-confirm-button-ok {
  background: #a5060d !important;
}

[id^="NotiflixConfirmWrap"] > div[class*="-content"] > div[class*="-head"] > h5 {
  color: #a5060d !important;
}

[id^="NotiflixConfirmWrap"].notiflix-confirm > div[class*="-content"] > div[class*="-head"] > div,
[id^="NotiflixConfirmWrap"].notiflix-confirm > div[class*="-content"] > div[class*="-head"] > h5,
[id^="NotiflixConfirmWrap"].notiflix-confirm > div[class*="-content"] > div[class*="-buttons"] > a {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

[id^="NotiflixConfirmWrap"].notiflix-confirm .notiflix-confirm-content {
  border-radius: 0.5rem !important;
}

.filepond--root .filepond--credits {
  display: none !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

#GlobalLoadingWrap {
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 4000;
  background: rgba(0, 0, 0, 0.8);
  -webkit-animation: loading-animation-fade 0.3s ease-in-out 0s normal;
  animation: loading-animation-fade 0.3s ease-in-out 0s normal;
  animation-duration: 400ms;
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  text-align: center;
  box-sizing: border-box;
}

[id^="GlobalLoadingWrap"] * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[id^="GlobalLoadingWrap"] > div[class*="-icon"] {
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-transition: top 0.2s ease-in-out;
  -o-transition: top 0.2s ease-in-out;
  transition: top 0.2s ease-in-out;
  margin: 0 auto;
  box-sizing: border-box;
}

[id^="GlobalLoadingWrap"] > div[class*="-icon"] img,
[id^="GlobalLoadingWrap"] > div[class*="-icon"] svg {
  max-width: unset;
  max-height: unset;
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
}

[id^="GlobalLoadingWrap"] > p {
  position: relative;
  margin: 10px auto 0;
  font-family: inherit !important;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.4;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}

[id^="GlobalLoadingWrap"] > p.nx-loading-message-new {
  -webkit-animation: loading-new-message-fade 0.3s ease-in-out 0s normal;
  animation: loading-new-message-fade 0.3s ease-in-out 0s normal;
}

.MuiDrawer-root .MuiList-root a.active li .MuiBox-root {
  background-color: rgb(0, 0, 0, 0.3);
}

.MuiDrawer-root .MuiList-root a.active li .MuiBox-root:hover {
  background-color: transparent;
}

.MuiDrawer-root .MuiList-root a li .MuiBox-root:hover {
  /* background-color: rgb(0, 0, 0, 0.2); */
  background-color: rgb(0, 0, 0, 0.2);
}

.highlight-animation {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: borderFadeIn;
}

@keyframes borderFadeIn {
  0% {
    border: 1px solid transparent;
    box-shadow: none;
  }

  75% {
    border: 1px solid rgb(227, 71, 71);
    box-shadow: 0 0 2px rgba(227, 71, 71, 0.5);
  }

  100% {
    border: 1px solid transparent;
    box-shadow: none;
  }
}

.ql-toolbar.ql-snow {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding-top: 0;
}

.ql-container.ql-snow {
  border: none;
}
