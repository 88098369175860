.wrapper-class {
    padding: 1rem 0.5rem 0.5rem;
    border: 1px solid #d2d6da;
    border-radius: 0.375rem;
  }
  .editor-class {
    /* padding: 1rem; */
    min-height: 280px;
  }
  .toolbar-class {
    
}